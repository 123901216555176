let data = {
    "Header": {
        "title1": "SOFplast",
        "title2": "Nové Zámky",
        "paragraph": "Predaj, montáž a servis kvalitných plastových okien a dverí."
    },
    "Features": [{
            "icon": "fa fa-handshake-o",
            "title": "Predaj",
            "text": "Zabezpečujeme predaj značkových plastových okien."
        },
        {
            "icon": "fa fa-window-restore",
            "title": "Montáž",
            "text": "Poskytujeme kvalitnú montáž a murárske práce."
        },
        {
            "icon": "fa fa-wrench",
            "title": "Servis",
            "text": "Skúsení pracovníci sa postarajú o precízny servis."
        }
    ],
    "About": {
        "paragraph1": "Firma sa zaoberá predajom montážnou plastových okien a dverí plus doplnkov. V roku 2003 zahájila výrobu z profilov od nemeckej značky Schüco a bolo použité identické kovanie. Všetky ďalšie dodávateľské firmy sú známe nielen na Slovensku ale aj v zahraničí čo prispieva k vysokej kvalite dodaneho diela.",
        "paragraph2": <div>Od roku 2011 poskytujeme <b>servis aj ostatných výrobcov</b>. V 2009 sa montovali prvé pred okenné rolety z hliníka, poháňané el. motorom. V súčastnosti sa portfólio zaoberá aj exteriérovými roletami a žalúziami z hliníka poháňanými elektromotorom, ktoré sú ovládané vypínačom či diaľkovým ovládaním. Buď predomietkovými, alebo podomietkovými (treba ich zahrnúť pred výstavbou otvorov).</div>,
        "Why": [
            "Kvalitné spracovanie",
            "Odolné materiály",
            "Rýchly servis"
        ],
        "Why2": [
            "Dlhoročné skúsenosti",
            "Profesionálny prístup",
            "Overené zákazníkmi",
        ]
    },
    "Gallery": {
        "PHOTO_SET_ZALUZIE_1": [
            {
                src: 'img/portfolio/01-large_new.jpg',
                width: 4,
                height: 3
            },
            {
                src: 'img/portfolio/02-large_new.jpg',
                width: 1,
                height: 2
            }
        ],
        "PHOTO_SET_ZALUZIE_2": [
            {
                src: 'img/portfolio/04-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/03-large_new.jpg',
                width: 4,
                height: 3
            }
        ],

        "PHOTO_SET_OKNA_1": [
            {
                src: 'img/portfolio/05-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/06-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/09-large_new.jpg',
                width: 4,
                height: 3
            },
        ],
        "PHOTO_SET_OKNA_2": [
            {
                src: 'img/portfolio/08-large_new.jpg',
                width: 4,
                height: 3
            },
            
            {
                src: 'img/portfolio/10-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/11-large_new.jpg',
                width: 1,
                height: 2
            },
        ],
        "PHOTO_SET_OKNA_3": [
            {
                src: 'img/portfolio/12-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/13-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/14-large_new.jpg',
                width: 1,
                height: 2
            },
        ],

        "PHOTO_SET_ROLETY_1": [
            {
                src: 'img/portfolio/17-large_new.jpg',
                width: 4,
                height: 3
            },
            {
                src: 'img/portfolio/18-large_new.jpg',
                width: 1,
                height: 2
            }
        ],
        "PHOTO_SET_ROLETY_2": [
            {
                src: 'img/portfolio/19-large_new.jpg',
                width: 4,
                height: 3
            },
            {
                src: 'img/portfolio/20-large_new.jpg',
                width: 4,
                height: 3
            },
            {
                src: 'img/portfolio/21-large_new.jpg',
                width: 4,
                height: 3
            }
        ],
        "PHOTO_SET_ROLETY_3": [
            {
                src: 'img/portfolio/22-large_new.jpg',
                width: 1,
                height: 2
            },
            {
                src: 'img/portfolio/23-large_new.jpg',
                width: 4,
                height: 3
            }
        ],
    },
    "Team": [{
            "img": "img/team/01.jpg",
            "name": "Štefan Gašparovský",
            "job": "Kontaktná osoba"
        }
    ],
    "Testimonials": [{
            "img": "img/testimonials/01.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/02.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        },
        {
            "img": "img/testimonials/03.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/04.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        },
        {
            "img": "img/testimonials/05.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/06.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        }
    ],
    
    "Contact": {
        "name": "Štefan Gašparovský",
        "phone": "+421 940 319 054",
        "email": "stefan.gasparovsky@gmail.com",
        "facebook": "https://www.facebook.com/sofplastsk-100165005565272",
        "service1": "- poradenstvo",
        "service2": "- zameranie",
        "service3": "- kalkulácia"
    },
}

export default data;