import Gallery from 'react-photo-gallery';

export const GalleryComponent = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Galéria</h2>
          <p>
            Prezrite si našu prácu
          </p>
        </div>
        <div className='row'>
          <h3>Žalúzie a dverové siete</h3>
          <br/>
          {props.data ? <Gallery photos={props.data.PHOTO_SET_ZALUZIE_1} /> : 'Loading'}
          {props.data ? <Gallery photos={props.data.PHOTO_SET_ZALUZIE_2} /> : 'Loading'}
          <br/>
          <br/>

          <h3>Plastové okná</h3>
          <br/>
          {props.data ? <Gallery photos={props.data.PHOTO_SET_OKNA_1} /> : 'Loading'}
          {props.data ? <Gallery photos={props.data.PHOTO_SET_OKNA_2} /> : 'Loading'}
          {props.data ? <Gallery photos={props.data.PHOTO_SET_OKNA_3} /> : 'Loading'}
          <br/>

          <h3>Rolety a murárske práce</h3>
          <br/>
          {props.data ? <Gallery photos={props.data.PHOTO_SET_ROLETY_1} /> : 'Loading'}
          {props.data ? <Gallery photos={props.data.PHOTO_SET_ROLETY_2} /> : 'Loading'}
          {props.data ? <Gallery photos={props.data.PHOTO_SET_ROLETY_3} /> : 'Loading'}
          <br/>

        </div>
      </div>
    </div>
  )
}
