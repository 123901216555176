import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_qtyt826', 'template_6pwcd8x', e.target, 'user_lnbJjIhuZ2Wusvu7ulMnj'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      )
      .then(() => setState({ ...initialState }));
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>Napíšte nám</h2>
                <p>
                  V prípade záujmu nás neváhajte kontaktovať. Vyplňte formulár nižšie a pošlite nám email, alebo zavolajte na telefónne číslo.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Meno'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Text správy'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Odoslať správu
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='section-title'>
              <div className='contact-item'>
                <h2>Kontakt</h2>
                <p>
                  <span>
                    <i className='fa fa-user-circle-o'></i> Meno
                  </span>
                  {props.data ? props.data.name : 'loading'}
                  <br/>
                  <br/>
                  {props.data ? props.data.service1 : 'loading'}
                  <br/>
                  {props.data ? props.data.service2 : 'loading'}
                  <br/>
                  {props.data ? props.data.service3 : 'loading'}
                </p>
                <br/>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Telefónne číslo
                  </span>{' '}
                  {props.data ? props.data.phone : 'loading'}
                </p>
                <br/>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> Email
                  </span>{' '}
                  {props.data ? props.data.email : 'loading'}
                </p>
                <br/>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            SOFplast &copy; {1900 + new Date().getYear()} | Designed with <i className='fa fa-coffee'></i> by{" "}
            <a href='https://www.linkedin.com/in/lp-11/' rel='nofollow'>
              CryptoSoft
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
