import { useEffect, useState } from 'react';
import SmoothScroll from 'smooth-scroll';
import { About } from './components/about';
import { Contact } from './components/contact';
import { Features } from './components/features';
import { GalleryComponent } from './components/gallery';
import { Header } from './components/header';
import { Navigation } from './components/navigation';
import { default as JsonData } from './data/data.js';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <GalleryComponent data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />
    </div>
  )
}

export default App
